import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "William King",
      position: "CEO @ SuperCopy",
      src: "images/testimonial/william.jpg",
      desc: "Nic consistently exceeds the expectations typical of an advisor. He masterfully strikes a delicate balance, offering insightful guidance while nurturing our team's development of unique perspectives. His pivotal role in accelerating our startup's growth, especially as we geared up for our first major enterprise contract, cannot be overstated. Nic's approach is a rare blend of creative technical prowess and deep empathy, allowing him to craft solutions that are as compassionate as they are effective. Whether in 1-on-1 sessions or leading comprehensive team workshops, Nic's focus on unlocking new opportunities is unparalleled. We are incredibly fortunate to have Nic in our corner, and with his support, we're confidently paving our path forward.",
    },
    {
      name: "Matt Melchiori",
      position: "COO @ Glass",
      src: "images/testimonial/matt.jpg",
      desc: "Nic is a true pro. Whether we were talking about 0-1 solutions, building for scale, or growing our team, Nic had a thoughtful, results-oriented approach that allowed us to move quickly and deliver with confidence. Nic evaluated and built recommendations for moving off legacy code, supplementing our team with the right talent, and improving our processes. I couldn't have been happier with Nic's partnership and technical leadership.",
      // rating: 5,
    },
    {
      name: "Tim Min",
      position: "CTO @ Supercopy",
      src: "images/testimonial/tim.jpg",
      desc: "Nic was truly revolutionary for our company. His constant guidance in both our technical direction and scalability of the company were crucial to helping us to where we are today. And ultimately, his care for the founders and their wellbeing is like none other.",
      // rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Client Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-25"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  {/* <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span> */}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;

import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Strategic Planning & Goal Alignment",
      desc: "Observe, listen, and learn at what stage you are in to determine the starting point: whether taking an idea to MVP for launch or scaling for hypergrowth.\nEnsure technology not only supports but enables the business by identifying opportunities for alignment between tech and business goals.\nConvert lessons from support into new features, improved UX, and engagement opportunities, fostering a culture that continually learns and adapts.",
      icon: "fas fa-desktop",
    },
    {
      name: " Investor Relations & External Communication",
      desc: "Talk to investors by providing a comprehensive view of the tech side of the business, assisting in setting and achieving funding goals.\nShare experiences from going through the fundraising process and achieving a successful exit, offering valuable insights into the journey for startups.\nBring an outside perspective to the founding team, delivering honest assessments committed to the success of both the founding team and the company.",
      icon: "fas fa-lightbulb",
    },
    {
      name: "Team Building & Leadership",
      desc: "Establish and nurture a tech team capable of evolving an MVP into an agile product organization.\nIdentify gaps in the tech leadership team and address them or start a tech practice from scratch, ensuring the right mix of skills and roles.\nFoster a culture of mentorship and growth, encouraging continuous improvement and personal development within the team.",
      icon: "fas fa-people-group",
    },
    {
      name: "Operational Excellence",
      desc: "Scale operations by identifying bottlenecks and addressing pain points to streamline processes.\nStreamline user support operations to enhance customer satisfaction and engagement.\nEstablish a clear process for moving from idea inception to feature launch, including measurement and implementation of improvements.\nMeasure and report on the health of operations while suggesting areas for improvement, ensuring the tech infrastructure efficiently supports business activities.",
      icon: "fas fa-bolt",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                        {service.desc.split("\n").map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <span style={{ paddingBottom: 10 + 'px', display: 'block' }}></span>
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
